import './App.css';
import AuthStateApp from './Authenticator';

function App() {
  return (
    <AuthStateApp />
  );
}

export default App;
