import React from "react";
import logo from '../secure-cabbage-logo-transparent.png';


export default function HomePage() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>
                    More content coming soon - stay tuned!
                </p>
                <a
                    className="App-link"
                    href="/onboard"
                    rel="noopener noreferrer"
                >
                    Onboard to SCC
                </a>
            </header>
        </div>
    )
}