import React from 'react';
import Button from '@mui/material/Button';

import axios from 'axios';

class OnboardPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            token: this.props.token,
            disableButton: false
        }
    }

    render() {
        const onboard = async() => {
            const apiEndpoint = this.props.config["REACT_API_ENDPOINT"]
            window.confirm("API Request sent, you should receive an activation email within the next couple of minutes.\nIf you're experiencing issues, reach out to a SCC admin")
            this.setState({disableButton: true})
            await axios.get(`${apiEndpoint}/onboard`, {
                headers: {
                    "authorization": this.state.token
                }
            })
        }
        return (
            <Button variant="contained" disabled={this.state.disableButton} onClick={() => onboard()}>
                Request Okta User
            </Button>
        )
    }
}

export default OnboardPage
