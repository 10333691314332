import React from 'react';

import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
import Amplify, { Auth } from 'aws-amplify';

import OnboardPage from './components/OnboardPage';

import * as configuration from './configuration.json';

import Button from '@mui/material/Button';
import HomePage from './components/HomePage';


const environment = process.env.REACT_APP_ENVIRONMENT || "dev"
const environmentConfig = configuration.default[environment]

var url = ''

console.log(environmentConfig)

if (environmentConfig) {
    const {
        REACT_MIDWAY_DOMAIN,
        REACT_MIDWAY_REDIRECT_URL,
        REACT_IDENTITY_POOL_ID,
        REACT_REGION,
        REACT_USER_POOL_ID,
        REACT_USER_POOL_WEB_CLIENT_ID,
    } = environmentConfig

    const oauth = {
        domain: REACT_MIDWAY_DOMAIN,
        scope: ['openid'],
        redirectSignIn: REACT_MIDWAY_REDIRECT_URL,
        redirectSignOut: REACT_MIDWAY_REDIRECT_URL,
        responseType: 'code',
        options: {
            AdvancedSecurityDataCollectionFlag: false
        }
    };

    const config = {
        Auth: {
            identityPoolId: REACT_IDENTITY_POOL_ID,
            region: REACT_REGION,
            userPoolId: REACT_USER_POOL_ID,
            userPoolWebClientId: REACT_USER_POOL_WEB_CLIENT_ID,
            oauth: oauth,
        }
    };

    url = 'https://' + oauth.domain + '/authorize?redirect_uri=' + oauth.redirectSignIn + '&response_type=' + oauth.responseType + '&client_id=' + config.Auth.userPoolWebClientId;
    Amplify.configure(config);
}

const getIDTokenFromMidwaySSO = async () => {
    const session = await Auth.currentSession({
        bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).catch(err => console.log(err));
    console.log("SSO data:", session);

    if (session) {
        const idToken = session.idToken
        return idToken
    } else {
        console.log("No ID token available")
        return null
    }
}

const AuthStateApp = () => {
    const [token, setToken] = React.useState();

    React.useEffect(() => {
        async function fetchData() {
            const idToken = await getIDTokenFromMidwaySSO();
            if (idToken) {
                setToken(idToken.jwtToken)
            }
        }
        fetchData();
    }, []);

    if (token) {
        return (
            <div className="App">
                <div>
                <Router>
                    <Routes>
                        <Route exact path="/" element={<HomePage/>}/>
                        <Route exact path="/onboard" element={<OnboardPage token={token} config={environmentConfig} />}/>
                    </Routes>
                </Router>
                </div>
            </div>
        )
    } else {
        console.log('redirecting');
        let sso = "";
        sso = (
            <Button iconAlign="center" variant="contained" href={url}>SSO</Button>
        )
        return (
            <div className="body">
                <div align="center">
                    {sso}
                </div>
            </div>
        )
    };
};

export default AuthStateApp;
